export const sendGaEvent = ({
	category,
	label,
	value,
	path,
}: {
	category?: string;
	label?: string;
	value?: number;
	path?: string;
}) => {
	if (process.env.NEXT_PUBLIC_SITE_URL?.startsWith('https://'))
		window.gtag('event', process.env.NEXT_PUBLIC_GA_TRACKING_ID as string, {
			event_category: category,
			event_label: label,
			value: value,
			page_path: path,
		});
};
