import HttpService from '@/core/services';
import {
	CHANGE_PASSWORD,
	CHECK_USER_EXISTENCE,
	FORGOT_PASSWORD,
	GET_AVATARS,
	LOGIN,
	ME,
	REGISTER,
	RESET_PASSWORD,
	UPDATE,
	UPDATE_USER_IMG,
	UPLOAD_COMPANY_LOGO,
} from '@/core/services/endpoints';
import { IProfilePasswordsValues } from '@/partials/sponsorshipForm/logic/entities';
import { UserDetail } from '@/core/models/user';

export const me = (): Promise<UserDetail> =>
	HttpService.ExecuteRequest({
		endPoint: ME,
	});

export const login = (data: { identifier: string; password: string }) =>
	HttpService.ExecuteRequest({
		endPoint: LOGIN,
		method: 'post',
		data,
	});

export const postForgotPassword = (data: { email: string }) =>
	HttpService.ExecuteRequest({
		endPoint: FORGOT_PASSWORD,
		method: 'post',
		data,
	});

export const postResetPassword = (data: {
	code: string;
	password: string;
	passwordConfirmation: string;
}) =>
	HttpService.ExecuteRequest({
		endPoint: RESET_PASSWORD,
		method: 'post',
		data,
	});

export const changePassword = (data: IProfilePasswordsValues) =>
	HttpService.ExecuteRequest({
		endPoint: CHANGE_PASSWORD,
		method: 'post',
		data,
	});

export const register = (data: { email: string; password: string }) =>
	HttpService.ExecuteRequest({
		endPoint: REGISTER,
		method: 'post',
		data,
	});

export const update = (id: string, data: Record<string, string | number | boolean>) =>
	HttpService.ExecuteRequest({
		endPoint: UPDATE(id),
		method: 'put',
		data,
	});

export const updateUserImg = (uuid: string, data: FormData) =>
	HttpService.ExecuteRequest({
		endPoint: UPDATE_USER_IMG(uuid),
		method: 'post',
		data,
		isFormData: true,
	});

export const uploadCompanyLogo = (uuid: string, data: FormData) =>
	HttpService.ExecuteRequest({
		endPoint: UPLOAD_COMPANY_LOGO(uuid),
		method: 'post',
		data,
		isFormData: true,
	});

export const checkUserExistence = (email: string) =>
	HttpService.ExecuteRequest({
		endPoint: CHECK_USER_EXISTENCE(email),
	});

export const getAvatars = (type: 'women' | 'men', page?: number, pageSize?: number) =>
	HttpService.ExecuteRequest({
		endPoint: GET_AVATARS(type, page, pageSize),
		tags: [],
	});
